import React, { useMemo, useState } from "react";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import cn from "classnames";
import Skeleton from "react-loading-skeleton";
import { useDiscountContext } from "@context/discountContext";
import { getDefaultDiscountForApplication } from "@/utils/discounts";
import { convertGender, rgbDataURL } from "@/components/puppyProfile/lib/utils";
import TextBody from "../TextBody/TextBody";
import TextDetail from "../TextDetail/TextDetail";

import styles from "./puppyCard.module.scss";
import PlayButton from "../videoPlayer/PlayButton";
import useMediaQuery from "@/utils/publicApi";

const VideoModal = dynamic(() =>
  import("@/components/videoPlayer/VideoModal").then((comp) => comp.VideoModal)
);

type PuppyCardProps = {
  dog: any;
  state?: boolean;
  isFavourite?: boolean;
  updateFavourite?: () => void;
  autosize?: boolean;
};

export default React.memo(function PuppyCard({
  dog,
  state,
  isFavourite,
  updateFavourite,
  autosize,
}: PuppyCardProps) {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const { images, video } = useMemo(() => {
    const images = dog?.images
      ?.filter((image) => !!image.small)
      ?.sort((i) => (i.small === dog?.profilePicture?.small ? -1 : 0));

    const video = dog?.videos?.[0]?.high && dog?.videos?.[0];

    return { images, video };
  }, []);

  const { discount: discountContext } = useDiscountContext();
  const discount = useMemo(() => {
    const _discount =
      getDefaultDiscountForApplication({ application: { dog } }) ||
      discountContext;
    return _discount?.applyDiscountByDefault && _discount;
  }, [dog, discountContext]);

  const showLocation = useMemo(
    () =>
      dog?.distance ||
      dog?.distance === 0 ||
      (state && (dog?.distance <= 200 || dog?.distance === undefined)),
    [dog, state]
  );

  const badgeContent = useMemo(() => {
    const registrations =
      dog?.registrations
        ?.split(",")
        .filter((r) => !!r && !["N/A", "AAPBA", "APRI", "UKCI"].includes(r)) ||
      [];

    if (registrations?.length) return registrations?.[0];
    if (dog?.training) {
      if (dog.training === "service") return "Service dog";
      if (dog.training === "therapy") return "Therapy dog";
    }

    return null;
  }, [dog]);

  if (!dog) {
    return (
      <div className={cn(styles.skeleton, autosize ? styles.autosize : null)}>
        <div className={styles.image}>
          <div>
            <Skeleton width="100%" height="257px" />
          </div>
        </div>
        <div className={styles.content}>
          <Skeleton width="100%" height="257px" />
        </div>
      </div>
    );
  }

  if (!images?.length) return null;

  return (
    <div className={cn(styles.card, { [styles.autosize]: autosize })}>
      <div className={styles.imageContainer}>
        <Link href={`/puppy/${dog?.slug}`} title={dog?.name} prefetch={false}>
          <Image
            height={254}
            width={254}
            className={styles.image}
            src={`${process.env.NEXT_PUBLIC_MEDIA_BUCKET}${images[0]?.small}`}
            alt={`${dog?.name} - ${dog?.breed?.name} ${dog?.gender}`}
            placeholder="blur"
            blurDataURL={rgbDataURL(238, 238, 238)}
            loading="lazy"
          />
        </Link>

        {!dog.isAvailable ? (
          <div className={styles.reserved}>
            <img
              className={styles.rectangle}
              src="/rectangle.svg"
              alt="rectangle"
            />
            <span className={styles.text}>RESERVED</span>
          </div>
        ) : (
          video && (
            <PlayButton
              height={!isMobile ? 42 : 36}
              width={!isMobile ? 42 : 36}
              containerStyle={{
                position: "absolute",
                top: 10,
                left: 10,
              }}
              onClick={() => setShowVideoModal(true)}
            />
          )
        )}

        {!!updateFavourite && (
          <div className={styles.favourite} onClick={updateFavourite}>
            <div
              className={cn(styles.icon, styles.heart, {
                [styles.isFavourite]: isFavourite,
              })}
            />
          </div>
        )}

        {!!badgeContent && (
          <div className={styles.badge}>
            <TextDetail weight="bold">{badgeContent}</TextDetail>
          </div>
        )}
      </div>

      <div className={styles.content}>
        <div className={styles.header}>
          <Link href={`/puppy/${dog?.slug}`} prefetch={false}>
            <TextBody size="m" weight="bold" className={styles.truncate}>
              {dog?.name}
            </TextBody>
          </Link>

          {dog?.salePrice?.subTotal && (
            <TextBody
              size="m"
              weight="bold"
              className={cn(styles.price, { [styles.discount]: !!discount })}
            >
              ${(dog?.salePrice?.subTotal - (discount?.amount || 0)) / 100}{" "}
              {dog?.currency !== "USD" && dog?.currency}
            </TextBody>
          )}
        </div>

        <div className={styles.header}>
          <TextBody size="s" className={styles.breed}>
            <span className={styles.truncate}>{dog?.breed?.name}</span>
            {dog?.gender && (
              <span className={styles.gender}>
                {convertGender(dog?.gender)}
              </span>
            )}
          </TextBody>

          {discount && (
            <TextDetail className={styles.originalPrice}>
              {dog?.salePrice?.puppyPrice}{" "}
              {dog?.currency !== "USD" && dog?.currency}
            </TextDetail>
          )}
        </div>

        {showLocation && (
          <TextBody size="s">{`${dog?.city}, ${dog?.state}`}</TextBody>
        )}
      </div>

      {!!video && (
        <VideoModal
          isOpen={showVideoModal}
          close={() => setShowVideoModal(false)}
          video={video}
        />
      )}
    </div>
  );
});
