import React from 'react'
import styles from './PlayButton.module.scss'

type PlayButtonProps = {
  width?: number
  height?: number
  // eslint-disable-next-line no-unused-vars
  onClick?: (e) => void
  isPlaying?: boolean
  containerStyle?: React.CSSProperties
}

export default function PlayButton({
  width = 70,
  height = 70,
  onClick = () => {},
  isPlaying,
  containerStyle = {},
}: PlayButtonProps) {
  return (
    <div
      className={styles['play-button-container']}
      onClick={onClick}
      style={{ width, height, borderRadius: width / 2, ...containerStyle }}
    >
      <div
        className={styles[isPlaying ? 'pause-button' : 'play-button']}
        onClick={onClick}
        style={{
          width,
          height,
          borderRadius: width / 2,
          backgroundRepeat: 'no-repeat',
        }}
      />
    </div>
  )
}
