export const getAvailability = (date) => {
  const now = Date.now()
  const available = Date.parse(date)
  const availableWeeks = parseInt(
    (available - now) / (7 * 24 * 60 * 60 * 1000)
  ) // One week

  if (now >= available) {
    return 'Available Now!'
  } else if (availableWeeks === 0) {
    return 'Available Now!'
  } else if (availableWeeks === 1) {
    return 'Available in 1 week!'
  }

  return `Available in ${availableWeeks} weeks!`
}
export const getNumberOfApplication = (number) => {
  if (number === 1) {
    return 'Currently in 1 person’s basket'
  } else if (number > 1) {
    return `Currently in ${number} people's baskets`
  } else {
    return null
  }
}

export const getNumberOfFavorites = (number) => {
  if (number === 1) {
    return 'Favorited by 1 person'
  } else if (number > 1) {
    return `Favorited by ${number} people`
  } else {
    return null
  }
}

export function iOS () {
  if (typeof navigator !== 'undefined') {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
  }
  return false
}

export const getAge = (str) => {
  const birthdate = new Date(str)
  const week = 7 * 24 * 60 * 60 * 1000
  const months = 4.348
  const weekNumber = Math.round((Date.now() - birthdate) / week)
  if (weekNumber <= 1) return '1 week old'
  if (weekNumber > 1 && weekNumber <= 10) return `${weekNumber} weeks old`
  if (weekNumber >= 11) return `${Math.floor(weekNumber / months)} months old`
}

export const convertDate = (str) => {
  if (!str) return null

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const [year, _month, _day] = str.split('-')
  const day = _day.slice(0, 2)
  const month = months[_month - 1]

  return `${day} ${month}, ${year}`
}

export const convertSize = (size) => {
  switch (size) {
    case 'L':
      return 'Large (40+lb)'
    case 'S':
      return 'Small (10-20lb)'
    case 'M':
      return 'Medium (20-40lb)'
    case 'XS':
      return 'Extra Small (<10lb)'
  }
}

export const convertPrice = (price, currency) => {
  const value = parseInt(price / 100)
  switch (currency) {
    case 'CAD':
      return `C$${value.toFixed(2)}`
  }
  return `$${value.toFixed(2)}`
}
export const convertFees = (price) => {
  if (typeof price === 'string') {
    const number = Number(price.slice(1, price.length))
    return (number * 0.029).toFixed(2)
  } else {
    const value = parseInt(price / 100)
    return (value * 0.029).toFixed(2)
  }
}

export const convertGender = (gender) => {
  switch (gender) {
    case 'Male':
      return 'Boy'
    case 'Female':
      return 'Girl'
  }
  return null
}

// function to enable a color placeholder for Next Image component

const keyStr =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='

const triplet = (e1, e2, e3) =>
  keyStr.charAt(e1 >> 2) +
  keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
  keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
  keyStr.charAt(e3 & 63)

export const rgbDataURL = (r, g, b) =>
  `data:image/gif;base64,R0lGODlhAQABAPAA${
    triplet(0, r, g) + triplet(b, 255, 255)
  }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`
