import React, { ReactNode, JSX } from "react";
import cn from "classnames";
import styles from "./TextBody.module.scss";

export type TextBodyProps = {
  id?: string;
  children: ReactNode | string;
  size: "s" | "m";
  weight?: "bold" | "regular";
  variant: "p" | "span" | "strong" | "em" | "b";
  className?: string;
};

const TextBody = ({
  id,
  children,
  size,
  weight,
  className,
  variant,
}: TextBodyProps): JSX.Element => {
  const getProps = () => ({
    ...(id && { id: `text-body-${id}` }),
    className: cn(
      styles.text,
      {
        [styles.bold]: weight === "bold",
        [styles.regular]: weight === "regular",
        [styles.s]: size === "s",
        [styles.m]: size === "m",
      },
      className
    ),
    children,
  });

  const variants = {
    p: <p {...getProps()} />,
    span: <span {...getProps()} />,
    strong: <strong {...getProps()} />,
    em: <em {...getProps()} />,
    b: <b {...getProps()} />,
  };

  return variants[variant];
};

TextBody.defaultProps = {
  variant: "p",
  size: "m",
  weight: "regular",
};

export default TextBody;
