import moment from 'moment'

export const getDefaultDiscountForApplication = ({ application }) => {
  const dogDiscount = application?.dog?.discount?.[Math.max((application?.dog?.discount || []).length - 1, 0)]
  const breedDiscount = application?.dog?.breed?.discount?.[Math.max((application?.dog?.breed?.discount || []).length - 1, 0)]
  const applicationDiscount = application?.discount?.[Math.max((application?.discount || []).length - 1, 0)]

  if (applicationDiscount?.applyDiscountByDefault) {
    return applicationDiscount
  } else if (breedDiscount?.applyDiscountByDefault && dogDiscount?.applyDiscountByDefault) {
    if (breedDiscount?.amount > dogDiscount?.amount) {
      return breedDiscount
    } else {
      return dogDiscount
    }
  } else if (breedDiscount?.applyDiscountByDefault) {
    return breedDiscount
  } else if (dogDiscount?.applyDiscountByDefault) {
    return dogDiscount
  } else {
    return undefined
  }
}

export const messages = (endDate, now) => {
  const endDay = new Date(endDate).getDate() + 1
  const nowDay = new Date(now).getDate()
  const month = moment.utc(new Date(endDate)).format('LL').split(' ').slice(0, 2).join(' ')
  const diffHours = moment.utc(now).endOf('day').fromNow()

  if (moment.utc(endDate).isAfter(now, 'month')) return `Offer ends ${month.split('').slice(0, month.length - 1).join('')}`
  if (moment.utc(endDate).isSame(now, 'month') && moment.utc(endDate).isAfter(now, 'day') && endDay - nowDay === 1) return 'Offer ends in 1 day'
  if (moment.utc(endDate).isSame(now, 'month') && moment.utc(endDate).isAfter(now, 'day')) return `Offer ends in ${endDay - nowDay} days`
  if (moment.utc(endDate).isSame(now, 'day') && diffHours.includes('minutes')) return 'Offer ends shortly at 11:59PM ET'
  if (moment.utc(endDate).isSame(now, 'day') && diffHours) return `Offer ends ${diffHours}  at 11:59PM ET `
}
